import React from "react";
import { Box, Text, Anchor } from "grommet";
export const MailComponentSwe = ({ textSize }) => {
    return (
        <Box gap="xsmall">
            <Text size={textSize} color="darkGreen">
                <Text size={textSize} weight="bold" color="darkGreen">
                    Riksdagen för framtiden - Klimatbeslut nu! </Text>
                är ett privat medborgarinitiativ. Det är inte knutet till något politiskt parti eller någon annan organisation. Det finns ingen finansiär och inga kommersiella eller andra liknande intressen bakom. Drivkraften är enbart att värna om en beboelig planet för våra efterkommande.
            </Text>
            <Text margin={{ top: "medium" }} size={textSize} weight="bold" color="darkGreen">
                Till dig som är riksdagsledamot:
            </Text>
            <Text size={textSize} weight="bold" color="darkGreen">
                Är du villig att vara med om att fatta beslut för framtiden, genom att rösta ja till en motion om att uppdra åt regeringen att genomföra tio punkter (se nedan)?
            </Text>
            <Text size={textSize} weight="bold" color="darkGreen">
                Vi ber dig att svara JA eller NEJ på frågan.
            </Text>
            <Text size={textSize} weight="bold" color="darkGreen">
                Kan du motivera ditt svar?
            </Text>
            <Text wordBreak="break-word" margin={{ top: "small" }} size={textSize} weight="bold" color="darkGreen">
                Skicka ditt svar till <Anchor>kontakt@riksdagenforframtiden.se</Anchor>. Ditt svar och din motivering kommer att synas på riksdagenforframtiden.se.
            </Text>
            <Text margin={{ top: "medium" }} size={textSize} color="darkGreen">
                De livsuppehållande systemen på rymdskeppet jorden är i ett kritiskt dåligt tillstånd. Matjorden, dricksvattnet, luften, väven av liv. De som sitter vid panelerna i maskinrummet har rapporterat i decennier – det här fungerar inte, de ropar: ställ om! Nu!
            </Text>
            <Text size={textSize} color="darkGreen">
                Det här är inte partipolitik. Det spelar ingen roll vilket parti du vill rösta på, av andra skäl. Det här handlar om allas vår framtid, om våra barns levnadsvillkor, om våra barnbarns överlevnad. Det bygger på vetenskapliga fakta. Vi har tillgång till lösningarna. Det som saknas nu är den politiska viljan.
            </Text>
            <Text size={textSize} color="darkGreen">
            Det är ett stort nätverk av forskare runt om i världen som visar att vi befinner oss i en global miljö- och klimatkris, nu senaste bekräftat av FNs vetenskapliga klimatpanels senaste uppdatering i sin 6:e rapport (IPCC AR6/WG1, augusti 2021). En av dessa forskare är den svenske professorn Johan Rockström, som inledde 2020-talet med att 1 januari 2020 tala i Sveriges radios P1's <Anchor href="https://sverigesradio.se/avsnitt/1417244">vinterprat</Anchor>.
            </Text>
            <Text size={textSize} color="darkGreen">
                Där lyfter han fram tio saker som vi måste göra – nu:
            </Text>
            <Text size={textSize} color="darkGreen">
                <ol type="1">
                    <li>Sluta helt att investera i infrastruktur som ger större utsläpp av växthusgaser.</li>
                    <li>Ta bort alla subventioner och skattelättnader till fossil energianvändning.</li>
                    <li>Inför ett tillräckligt högt pris på kol, för alla sektorer i samhället, tillsammans med importbegränsningar för produktion baserad på kol.</li>
                    <li>Stöd EU’s arbete med klimatomställningen.</li>
                    <li>Ställ om matproduktionen till stöd för regenerativt jordbruk, lokal svensk produktion och större andel växtbaserad kost.</li>
                    <li>Säkra naturens kolsänkor, vattenflöden och myller av liv, och ge Sveriges miljömål högsta prioritet till 2030.</li>
                    <li>Se till att finanssektorns verksamhet hålls inom planetens gränser.</li>
                    <li>Inför nya principer för ekonomisk utveckling baserat på cirkulär ekonomi och mänsklig utveckling.</li>
                    <li>Inför en tvingande klimatlag, i så många länder som möjligt.</li>
                    <li>Starta ett globalt jätteprojekt: välfärd och rättvisa inom planetens gränser på tio år.</li>
                </ol>
            </Text>
            <Text size={textSize} color="darkGreen">
                Varför ska just vi börja? Alla länder behöver göra detsamma. Men vi i Sverige har bland de bästa förutsättningarna att klara det, av alla länder. Om inte vi kan, vem kan då? Vi måste visa att det går. Vi kan visa att det går bra, att vi kan genomföra den här omställningen bort från fossilberoende, och ändå ha mat på tallriken. Ja vi kan visa att vi lyckas med det, och att det till och med får oss att må bättre än vi gör nu.
            </Text>
            <Text size={textSize} color="darkGreen">
                Det är inte längre bara bråttom, det är helt akut, det måste ske nu.
            </Text>
        </Box>
    )
}