import React from "react";
import { Box, Text, Anchor } from "grommet";
export const MailComponentEng = ({ textSize }) => {
    
    return (
        <Box gap="xsmall">
            <Text size={textSize} color="darkGreen">
                <Text size={textSize} weight="bold" color="darkGreen">
                    Parliament for the Future </Text>
                 is a citizen’s initiative. It’s not affiliated with any political party or organization. There’s no financier, no commercial or other similar interests behind. The driving force is only to safeguard a livable planet for the generations to come.
            </Text>
            <Text margin={{top:"medium"}} size={textSize} weight="bold" color="darkGreen">
                Dear Member of Parliament:
            </Text>
            <Text size={textSize} weight="bold" color="darkGreen">
            Are you willing to make the necessary decisions for the future, by voting in favor of a motion to commission our government to decide on ten essential action points for a sustainable future (see below)?
            </Text>
            <Text size={textSize} weight="bold" color="darkGreen">
                We ask you to answer this question with a YES or a NO.
            </Text>
            <Text size={textSize} weight="bold" color="darkGreen">
                Can you justify your answer?
            </Text>
            <Text margin={{top:"small"}} size={textSize} weight="bold" color="darkGreen">
                Send your answer to <Anchor>kontakt@riksdagenforframtiden.se</Anchor>.Your answer and your justification will be visible at riksdagenforframtiden.se.
            </Text>
            <Text margin={{top:"medium"}} size={textSize} color="darkGreen">
                The life support systems on spaceship Earth are currently in a critically bad condition. Freshwater, soils, climate, the very web of life. Human activities are changing Earth’s climate in unprecedented ways leading to the defining crisis of our time. The ones monitoring the dashboards in the engine room have been reporting for decades: this is not working. They call for transformation! Now!
            </Text>
            <Text size={textSize} color="darkGreen">
                It doesn’t matter which party you vote for. This is about all our futures, about the living conditions of our children, about our grandchildren’s survival. This is a code red for humanity. It’s based on scientific facts. We have the solutions. What’s missing now is the political will.
            </Text>
            <Text size={textSize} color="darkGreen">
            A large network of researchers around the world have shown that we’re in a global environmental and climate crisis, most recently confirmed by the UN’s Intergovernmental Panel on Climate Change in its latest, 6th scientific assessment report (IPCC AR6 / WG1, August 2021). One of these researchers is the Swedish professor Johan Rockström, who began the 2020s by holding a speech on Swedish Radio, on 1 January, 2020. You can listen to the radio show <Anchor href="https://sverigesradio.se/avsnitt/1425542">here.</Anchor>
            </Text>
            <Text size={textSize} color="darkGreen">
                In his speech he calls for ten essential things we must do - now:
            </Text>
            <Text size={textSize} color="darkGreen">
                <ol type="1">
                    <li>Stop all investments in infrastructure that contributes to enhanced greenhouse gas emissions.</li>
                    <li>Remove all subsidies and tax credits for fossil energy use.</li>
                    <li>Introduce a sufficiently high price of coal, for all sectors of society, together with import restrictions for coal-based production goods.</li>
                    <li>Support EU actions on climate change toward societal transformation.</li>
                    <li>Transform the food production system in support of regenerative agriculture, local Swedish food production and more plant-based diets.</li>
                    <li>Secure nature's carbon sinks, water flows and webs of life, and give Sweden's Environmental Goals the highest priority by 2030.</li>
                    <li>Ensure that the financial sector's operations are kept within the planetary boundaries.</li>
                    <li>Introduce new principles for economic development based on circular economy and human development.</li>
                    <li>Introduce a cogent Climate Act, in as many countries as possible.</li>
                    <li>Start a global mega project: welfare and justice within the planetary boundaries in ten years.</li>
                </ol>
            </Text>
            <Text size={textSize} color="darkGreen">
                Why should we be the ones to start? All countries need to do the same. But Sweden is one of the countries, if not the country, with the best conditions to successfully manage a societal transformation. If we can’t do it, who can? We have to show the world it’s possible. We can show that it’ll be alright, that we can carry out this transformation, and still have food on our plate. We can show the world that we succeed, and that it even makes us feel better than we do today.
            </Text>
            <Text size={textSize} color="darkGreen">
                There’s no time for delay and no room for excuses, it’s now or never.
            </Text>
        </Box>
    )
}